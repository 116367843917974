/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// layout file, like app/views/layouts/application.html.erb

require('jquery')
import 'bootstrap3/dist/js/bootstrap';
require("@rails/ujs").start()
// require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")

require('chosen-jquery/lib/chosen.jquery')
require('select2/dist/js/select2')
require('bootstrap-notify/bootstrap-notify')
require('timeago')

import Cookies from 'js-cookie'

require.context('../images', true);

var fn = function() {
  $('time[data-time-ago]').timeago();
};
$(fn);
$(document).on('turbolinks:load page:load ajax:success', fn);

window.init = function() {
    const title = $("[name='search[title]']");
    const author = $("[name='search[author]']");
    const summary = $("[name='search[summary]']");
    const saved = $("[name='saved_search_id']");
    const category_required = $("[name='search[category_required][]']");
    const category_optional = $("[name='search[category_optional][]']");
    const category_optional_exclude = $("[name='search[category_optional_exclude]']");
    const character_required = $("[name='search[character_required][]']");
    const character_optional = $("[name='search[character_optional][]']");
    const character_optional_exclude = $("[name='search[character_optional_exclude]']");
    const tags_include_ids = $("[name='search[tags_include_ids][]']");
    const tags_exclude_ids = $("[name='search[tags_exclude_ids][]']");
    const fandoms = $("[name='search[fandoms][]']");
    const crossovers = $("[name='search[crossovers][]']");
    const include_source_fandoms = $("[name='search[include_source_fandoms]']");
    const exclude_crossover_fandoms = $("[name='search[exclude_crossover_fandoms]']");
    const status = $("[name='search[status]']");
    const language = $("[name='search[language]']");
    const rating = $("[name='search[rating][]']");
    const read_filter = $("[name='search[read_filter]']");
    const relationship = $("[name='search[relationships][0][characters][]']");
    const wordcount_lower = $("[name='search[wordcount_lower]']");
    const wordcount_upper = $("[name='search[wordcount_upper]']");
    const favorites_lower = $("[name='search[favorites_lower]']");
    const favorites_upper = $("[name='search[favorites_upper]']");
    const chapters_lower = $("[name='search[chapters_lower]']");
    const chapters_upper = $("[name='search[chapters_upper]']");
    const published_after = $("[name='search[published_after]']");
    const published_before = $("[name='search[published_before]']");
    const updated_after = $("[name='search[updated_after]']");
    const updated_before = $("[name='search[updated_before]']");
    const sort_by = $("[name='search[sort_by]']");
    const order_by = $("[name='search[order_by]']");

    const selectors = {title,author,summary,saved,
        category_required,category_optional,category_optional_exclude,
        character_required,character_optional,character_optional_exclude,
        fandoms,crossovers,include_source_fandoms,exclude_crossover_fandoms,
        status,language,rating,read_filter,relationship,
        tags_include_ids, tags_exclude_ids,
        wordcount_lower, wordcount_upper,
        favorites_lower, favorites_upper,
        chapters_lower, chapters_upper,
        published_after, published_before, updated_after, updated_before,
        sort_by,order_by
    };

    saved.chosen({allow_single_deselect: true}).off('change').on('change', (e,p) => navigate(e,p));
    category_required.chosen({max_selected_options: 2});
    category_optional.chosen();
    character_required.chosen({max_selected_options: 4});
    character_optional.chosen();
    tags_include_ids.chosen();
    tags_exclude_ids.chosen();
    fandoms.chosen().off('change').on('change', (e,p) => updateFandom(e,p));
    crossovers.chosen({allow_single_deselect: true}).off('change').on('change', (e,p) => updateCrossover(e,p));
    exclude_crossover_fandoms.click((e,p) => updateCrossover(e,p));
    status.chosen({allow_single_deselect: true});
    language.chosen({allow_single_deselect: true});
    rating.chosen();
    read_filter.chosen();
    sort_by.chosen();
    order_by.chosen();

    $('.character_list').toArray().forEach(elem => {
        $(elem).chosen({max_selected_options: 4});
    });

    $('#clear_fandoms').click(function() {
        fandoms.val([]);
        return fandoms.trigger('chosen:updated');
    });

    $('#clear_crossovers').click(function() {
        crossovers.val([]);
        return crossovers.trigger('chosen:updated');
    });

    $('#clear_character_required').click(function() {
        character_required.val([]);
        return character_required.trigger('chosen:updated');
    });

    $('#clear_character_optional').click(function() {
        character_optional.val([]);
        return character_optional.trigger('chosen:updated');
    });

    $('#advanced_search :checkbox').click(function() {
        return updateCheckboxLabel($(this));
    });

    var updateCheckboxLabel = function(elem) {
        const label_elem = $(`label[for='${elem.attr("name")}']`);
        let label = label_elem.data("type");
        if (elem.is(':checked')) {
            label = `Excluded ${label}`;
        } else {
            label = `Included ${label}`;
        }

        return label_elem.text(label);
    };

    var navigate = function(e,p) {
        const opt = $("option:selected", $("[name='saved_search_id']"))[0];

        if((opt.value !== null) && (opt.value !== "")) {
            return $.getJSON(`/user/saved_searches/${opt.value}`, function(data) {
                const s = data.search;

                title.val(s.title);
                author.val(s.author);
                summary.val(s.summary);

                fandoms.val(s.fandoms);
                fandoms.trigger('chosen:updated');
                crossovers.val(s.crossovers);
                crossovers.trigger('chosen:updated');
                include_source_fandoms.prop('checked', s.include_source_fandoms);
                exclude_crossover_fandoms.prop('checked', s.exclude_crossover_fandoms);

                s.relationships.forEach(() => clone_relationship_row());

                updateFandom().done(() =>
                    // wait for crossovers change before adding chars
                    (() => {
                        const result = [];
                        for (let prop of ['category', 'character']) {
                            for (let type of ['required', 'optional']) {
                                const propname = prop + "_" + type;
                                selectors[propname].val(s[propname]);
                                selectors[propname].trigger('chosen:updated');
                            }
                            const toggleprop = prop + "_optional_exclude";
                            selectors[toggleprop].prop('checked', s[toggleprop]);
                            result.push(updateCheckboxLabel(selectors[toggleprop]));
                        }

                        $('.shiprow').toArray().forEach((elem) => $(elem).remove());
                        for (let i = 0; i < s.relationships.length; i++) {
                            clone_relationship_row();
                            const characters = $('[id="search[relationships][' + i + '][characters][]"]');
                            characters.val(s.relationships[i]['characters']);
                            characters.trigger('chosen:updated');

                            if (s.relationships[i]['partial'] != null) {
                                $('[id="search[relationships][' + i + '][partial]"]').prop('checked', 'checked');
                            }

                            if (s.relationships[i]['exclude'] != null) {
                                $('[id="search[relationships][' + i + '][exclude]"]').prop('checked', 'checked');
                            }
                        }

                        if (s.relationships.length == 0) {
                            clone_relationship_row();
                        }

                        return result;
                    })()
                );

                return (() => {
                    const result = [];
                    for (let prop of ['wordcount_lower', 'wordcount_upper', 'favorites_lower', 'favorites_upper',
                        'chapters_lower', 'chapters_upper', 'average_words_per_chapter_lower',
                        'published_after', 'published_before', 'updated_after', 'updated_before',
                        'tags_include_ids', 'tags_exclude_ids',
                        'rating', 'read_filter', 'language', 'status', 'sort_by', 'order_by']) {
                        if (s[prop]) {
                            selectors[prop].val(s[prop]);
                            result.push(selectors[prop].trigger('chosen:updated'));
                        }
                    }
                    return result;
                })();
            });
        }
    };

    var updateCrossover = function(e,p) {
        const fandom = $("option:selected", $("[name='search[fandoms][]']"));
        const crossover = $("option:selected", $("[name='search[crossovers][]']"));
        var params = (fandom.map(function(c) { return `fandom[]=${$(this).val()}`; })).toArray();

        if (exclude_crossover_fandoms.prop('checked')) {
          params = params.concat(`fandom[]=-1`);
          params = params.concat((crossover.map(function(c) { return `exclude_fandom[]=${$(this).val()}`; })).toArray());
        } else {
          params = params.concat((crossover.map(function(c) { return `fandom[]=${$(this).val()}`; })).toArray());
        }

        if (params.length > 0) {
            return $.getJSON(`/characters?${params.join('&')}`, function(data) {
                for (let select of $('select.character_list').toArray()) {
                  select = $(select);
                  let existing = select.val();
                  $('option', select).remove();
                  select.append(data.map(v => new Option(v.name, v.id)));
                  select.val(existing);
                  select.trigger('chosen:updated');
                }

                let selectTemplate = $('#shiprow-template select');
                $('option', selectTemplate).remove();
                selectTemplate.append(data.map(v => new Option(v.name, v.id)));

            });
        } else {
            return $.Deferred().resolveWith({});
        }
    };

    var updateFandom = function(e,p,c) {
        let crossover, crossovers_selected, fandom, fandoms_selected, params;
        return updateCrossover(e,p).done(function() {},

            (fandom = $("option:selected", $("[name='search[fandoms][]']"))),
            (crossover = $("option:selected", $("[name='search[crossovers][]']"))),

            (fandoms_selected = (fandom.map(function(c) { return $(this).val(); })).toArray()),
            (crossovers_selected = (crossover.map(function(c) { return $(this).val(); })).toArray()),

            (params = (fandom.map(function(c) { return `fandom[]=${$(this).val()}`; })).toArray()
                .concat((crossover.map(function(c) { return `fandom[]=${$(this).val()}`; })).toArray())),

            params.length > 0 ?
                $.getJSON(`/crossovers?${params.join('&')}`, function(data) {
                    const select = $("[name='search[crossovers][]']");
                    const options = select.prop('options');
                    $('option', select).remove();

                    options[options.length] = new Option('', '');
                    $.each(data, (k,v) => options[options.length] = new Option(v.name,v.id));

                    select.val(crossovers_selected);
                    return select.trigger('chosen:updated');
                }) : undefined
        );
    };
};

$(function() {
    $(document).ajaxSend(function(e, xhr, options) {
        var token = $('meta[name="csrf-token"]').attr('content');
        if (token) xhr.setRequestHeader('X-CSRF-Token', token);
    });


  var themeSwitch = document.getElementById('darkmode');
  if(themeSwitch) {
    initTheme(); // on page load, if user has already selected a specific theme -> apply it

    themeSwitch.addEventListener('click', function(event){
      resetTheme(); // update color theme
    });

    function initTheme() {
      var darkThemeSelected = (localStorage.getItem('themeSwitch') !== null && localStorage.getItem('themeSwitch') === 'dark');
      var body = $('body');

      if (darkThemeSelected) {
        Cookies.set('themeSwitch', 'dark', { expires: 365 });
        body.addClass('theme-dark');
      } else {
        Cookies.remove('themeSwitch');
      }
    }

    function resetTheme() {
      var body = $('body');
      if(!body.hasClass('theme-dark')) { // dark theme has been selected
        body.addClass('theme-dark');
        Cookies.set('themeSwitch', 'dark', { expires: 365 });
        localStorage.setItem('themeSwitch', 'dark'); // save theme selection
      } else {
        body.removeClass('theme-dark');
        Cookies.remove('themeSwitch');
        localStorage.removeItem('themeSwitch'); // reset theme selection
      }
    }
  }


  $('.report-broken').click(function(e) {
        e.stopImmediatePropagation();
        const story_id = $(this).data('story-id');

        return $.post('/search/report_broken', {'story_id': story_id})
            .done(data => alert('Reported link as broken! Thank you. It may take some time to deindex this story.'));
    });

    $('.read-it-later').click(function(e) {
        e.stopImmediatePropagation();
        const selector = $(this);
        const story_id = selector.data('story-id');
        const origin = selector.data('story-origin');
        const state = selector.data('state');
        let method = 'POST';
        if (state === 'in-queue') {
            method = 'DELETE';
        }

        return $.ajax({
            url: '/user/queue',
            method,
            data: {
              'story_id': story_id,
              'origin': origin,
            }
        })
            .done(_ => {
                if (method === 'POST') {
                    selector.data('state', 'in-queue');
                    return selector.html('<i class="fa fa-minus"></i> Remove from queue');
                } else {
                    selector.data('state', 'out-of-queue');
                    return selector.html('<i class="fa fa-plus"></i> Add to queue');
                }
            })
            .fail(response => {
                if (response.status !== 409) {
                    return alert(`Failed to add/remove story to queue: ${response.statusText}`);
                }
            });
    });

    $('.hide-author').click(function(e) {
        e.stopImmediatePropagation();
        const selector = $(this);
        const author_id = selector.data('author-id');
        const state = selector.data('state');

        if (!confirm('Are you sure you want to hide this author and all of their stories?')) {
            return;
        }

        return $.ajax({
            url: '/user/hidden/author',
            method: 'PUT',
            data: {'author_id': author_id}
        })
            .done(_ => {
                let name = $(`a.author-link[data-author=${author_id}]`).text();

                $.notify({
                    // options
                    message: `Author <strong>${name}</strong> hidden successfully.`
                },{
                    // settings
                    type: 'success'
                });
            })
            .fail(response => alert(`Failed to hide author: ${response.statusText}`));
    });

    $('.hide-story').click(function(e) {
        e.stopImmediatePropagation();
        const selector = $(this);
        const story_id = selector.data('story-id');
        const state = selector.data('state');

        let method = 'PUT';
        if (state === 'hidden') {
            method = 'DELETE';
        }

        return $.ajax({
            url: '/user/hidden/story',
            method: method,
            data: {'story_id': story_id}
        })
          .done(_ => {
            if (method === 'PUT') {
              selector.data('state', 'hidden');
              return selector.html('<i class="fa fa-eye"></i> Undo hide story');
            } else {
              selector.data('state', 'shown');
              return selector.html('<i class="fa fa-eye-slash"></i> Hide story');
            }
          })
          .fail(response => {
            if (response.status !== 409) {
              return alert(`Failed to hide story: ${response.statusText}`);
            }
          });
    });

    $('.unhide-author').click(function(e) {
        e.stopImmediatePropagation();
        const selector = $(this);
        const author_id = selector.data('author-id');
        const state = selector.data('state');

        return $.ajax({
            url: '/user/hidden/author',
            method: 'DELETE',
            data: {'author_id': author_id}
        })
            .done(_ => {
                $(`tr[data-author-id=${author_id}]`).remove()
            })
            .fail(response => alert(`Failed to unhide author: ${response.statusText}`));
    });

    $('.unhide-story').click(function(e) {
        e.stopImmediatePropagation();
        const selector = $(this);
        const story_id = selector.data('story-id');
        const state = selector.data('state');

        return $.ajax({
            url: '/user/hidden/story',
            method: 'DELETE',
            data: {'story_id': story_id}
        })
            .done(_ => {
                $(`tr[data-story-id=${story_id}]`).remove()
            })
            .fail(response => alert(`Failed to unhide story: ${response.statusText}`));
    });

    $('#add_relationships').click(e => {
        e.stopImmediatePropagation();
        clone_relationship_row();
    });

    if ($('#search_controller').data('search') != null) {
        const searchParams = new URLSearchParams(location.search);
        let emptyKeys = [];
        for (const pair of searchParams) {
            if (pair[1] === "") {
                emptyKeys.push(pair[0]);
            }
        }
        for (const key of emptyKeys) {
            searchParams.delete(key);
        }

        window.history.replaceState(null, "", '/search?' + searchParams.toString());
    }

    return $('a.story-link').on('mousedown', function(e) {
        e.stopImmediatePropagation();
        const search = $('#search').data('search');
        const story_id = $(this).data('story-id');
        const origin = $(this).data('story-origin');
        const searchParams = new URLSearchParams(location.search);
        const page = searchParams && searchParams.has('page') ? searchParams.get('page') : 1;
        return $.post('/search/click', {
            'story_id': story_id,
            'search': search,
            'page': page,
            'origin': origin,
        });
    });
});



function clone_relationship_row() {
    let rows = $('.shiprow').toArray();
    let next_index = 0;
    if (rows.length > 0) {
        next_index = Math.max(...rows.map(elem => $(elem).data('ship-index'))) + 1;
    }

    const new_row = $('#shiprow-template').clone();

    // Update ids
    new_row.removeAttr('id');
    const select = new_row.find('select');
    select.attr('name', 'search[relationships][' + next_index + '][characters][]');
    select.attr('id', 'search[relationships][' + next_index + '][characters][]');
    select.addClass('character_list');
    const button = new_row.find('button');
    button.attr('id', 'remove_relationships[' + next_index + ']');
    button.attr('name', 'remove_relationships[' + next_index + ']');
    const checkbox_partial = new_row.find('input[type=checkbox].ship_partial');
    checkbox_partial.attr('id', 'search[relationships][' + next_index + '][partial]');
    checkbox_partial.attr('name', 'search[relationships][' + next_index + '][partial]');
    const checkbox_exclude = new_row.find('input[type=checkbox].ship_exclude');
    checkbox_exclude.attr('id', 'search[relationships][' + next_index + '][exclude]');
    checkbox_exclude.attr('name', 'search[relationships][' + next_index + '][exclude]');

    if (next_index > 0) {
        button.removeAttr('disabled');
    }

    button.click(() => {
        new_row.remove();
    });
    new_row.addClass('shiprow');

    $('#shippingcontainer').append(new_row);
    new_row.data('ship-index', next_index);
    new_row.removeClass('hidden');
    select.chosen({max_selected_options: 4});
}
